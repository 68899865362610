import React from 'react';
import { useIntl } from 'react-intl';
import { colors } from '../../theme/theme';

type CarStatusProps = {
  status: any;
  style?: any;
};

const CarStatus: React.FC<CarStatusProps> = ({ status, style }) => {
  const intl = useIntl();
  return (
    <p data-testid={`CarStatus-${status}`} style={style} className={status}>
      {intl.formatMessage({ id: `fleetadmin.carStatus.${status}` })}
      <style jsx>
        {`
          p {
            display: inline-block;
            margin-bottom: 0;
            padding: 0 8px;
            font-size: 12px;
            border-radius: 10px;
            border: 1px solid ${colors.gray100};
          }
          p.EQUIPMENT_READY {
            background: ${colors.white};
            color: ${colors.equipment_ready};
            border-color: ${colors.equipment_ready};
            min-width: 120px !important;
          }
          p.EQUIPPED {
            background: ${colors.equipped};
            color: white;
            border-color: ${colors.equipped};
          }
          p.RELEASE_READY {
            background: ${colors.white};
            color: ${colors.release_ready};
            border-color: ${colors.release_ready};
          }
          p.RELEASED {
            background: ${colors.released};
            color: ${colors.white};
            border-color: ${colors.released};
          }
          p.BLOCKED {
            background: ${colors.white};
            color: ${colors.blocked};
            border: 2px solid ${colors.blocked};
          }
          p.RENTED {
            background: ${colors.rented};
            color: ${colors.dark};
            border-color: ${colors.dark};
          }
          p.TERMINATED {
            background: ${colors.white};
            color: ${colors.terminated};
            border-color: ${colors.terminated};
          }
          p.REMOVED {
            background: ${colors.white};
            color: ${colors.removed};
            border-color: ${colors.removed};
          }
        `}
      </style>
    </p>
  );
};

export default CarStatus;
