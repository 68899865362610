import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table, Skeleton } from 'antd';
import getColumnsWidth from '../../../utils/getColumnsWidth';
import { ViewMode } from '../../../hooks/useViewMode';
import styles from './TableLayout.styles';
import isDesktop from '../../../utils/sizeHelpers';
import { selectAppliedFiltersHeight } from '../../../redux/store/ui/common';
import { selectViewMode } from '../../../redux/store/ui/common/selectors';
import { useLoadingViewMode } from '../../../hooks/useLoadingViewMode';

type TableLayoutProps = {
  rowKey: string;
  columns: any[];
  dataSource: any;
  selectedId?: any;
  totalNumber?: number;
  current?: number;
  onClickRow: Function;
  onPageChanged?: (page: number, pageSize?: number) => void;
  rowSelection?: any | null;
  expandedRowRender?: any;
};
export const DEFAULT_PAGE_SIZE = 50;

const LoadingTable = () => {
  return (
    <>
      <div className="loaderContainer">
        <Skeleton active paragraph />
        <Skeleton active paragraph />
        <Skeleton active paragraph />
        {isDesktop() && (
          <>
            <Skeleton active paragraph />
            <Skeleton active paragraph />
          </>
        )}
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

const EmptyTable = () => {
  return (
    <>
      <div className="loaderContainer">
        <img
          className="empty"
          id="eyeIcon"
          src="/images/table/empty.svg"
          width="50"
          height="50"
        />
        <div>No data available</div>
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

const TableLayout: React.FC<TableLayoutProps> = ({
  rowKey,
  columns,
  dataSource,
  selectedId,
  totalNumber,
  onClickRow,
  onPageChanged,
  current,
  rowSelection,
  expandedRowRender,
}) => {
  const viewMode = useSelector(selectViewMode);
  const loading = useLoadingViewMode(viewMode);
  const appliedFiltersHeight = useSelector(selectAppliedFiltersHeight);

  const totalWidth = getColumnsWidth(columns);
  if (
    totalWidth &&
    typeof window !== 'undefined' &&
    totalWidth < window?.innerWidth - 232
  ) {
    if (columns[columns.length - 1].width) {
      columns[columns.length - 1].width = undefined;
    }
  }

  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const onChangePageSize = (page: number, pageSize: number) => {
    setPageSize(pageSize);
    if (onPageChanged) onPageChanged(page, pageSize);
  };

  const onPageChange = (page: number, pageSize: number) => {
    if (onPageChanged) onPageChanged(page, pageSize);
  };

  const heightOffset =
    viewMode === ViewMode.CARE
      ? appliedFiltersHeight + 21
      : appliedFiltersHeight;

  useEffect(() => {
    setTimeout(() => {
      const sizeChanger = document.querySelector('.ant-select-selection-item');
      if (sizeChanger) {
        sizeChanger.setAttribute('data-testid', 'TableLayout-sizeChanger');
      }
    }, 1300);
  }, []);
  return (
    <>
      {loading ? (
        <LoadingTable />
      ) : dataSource?.length! > 0 ? (
        <div className="wrapper">
          <Table
            className="table-layout"
            rowClassName={(record) =>
              record.id === selectedId ? 'selected-row' : ''
            }
            columns={columns}
            dataSource={dataSource}
            scroll={{
              x: 'calc(100vw - 232px)',
              y: `calc(100vh - ${(isDesktop() ? 270 : 215) + heightOffset}px)`,
            }}
            pagination={{
              total: totalNumber,
              defaultPageSize: DEFAULT_PAGE_SIZE,
              pageSize: pageSize,
              ...(current ? { current } : {}),
              showSizeChanger: isDesktop(),
              onShowSizeChange: onChangePageSize,
              onChange: onPageChange,
              pageSizeOptions: ['50', '100', '150', '200'],
              simple: !isDesktop(),
              position: ['bottomLeft'],
            }}
            rowKey={(record: any) => `${rowKey}-table-row-${record?.id!}`}
            onRow={(record) => ({
              onClick: (e) => {
                e.preventDefault();
                onClickRow(record);
              },
              ref: (Element) => {
                if (Element) {
                  const cells = Element.querySelectorAll('td');
                  cells.forEach((cell, cellIndex) => {
                    cell.setAttribute(
                      'data-testid',
                      `TableLayout-row-${record.id}-cell-${cellIndex}`
                    );
                  });
                }
              },
            })}
            rowSelection={rowSelection}
            showSorterTooltip={false}
            expandable={{ expandedRowRender }}
          />
        </div>
      ) : (
        <EmptyTable />
      )}
      <style jsx>{styles}</style>
    </>
  );
};

export default TableLayout;
