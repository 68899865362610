import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Button } from 'antd';
import styles from './UserBICModal.styles';
import {
  getIsVisibleModal,
  closeModal,
  selectSelectedPayment,
} from '../../../redux/store/ui/common';
import DrawerModal from '../../shared/DrawerModal';
import { useIntl } from 'react-intl';
import {
  selectSelectedUser,
  updateUser,
} from '../../../redux/store/userData/users';

const UserBICModal: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const selectedPayment = useSelector(selectSelectedPayment);

  const selectedUser = useSelector(selectSelectedUser);
  const [bic, setBIC] = useState(selectedPayment?.sepa?.bic);

  const visible = useSelector(getIsVisibleModal('UserBICModal'));

  const handleCancel = () => {
    dispatch(closeModal('UserBICModal'));
  };

  const handleOnSubmit = (e: any) => {
    e.preventDefault();

    dispatch(updateUser(selectedUser.id, { activeSepaBic: bic }));
    handleCancel();
  };

  useEffect(() => {
    if (visible) {
      setBIC(selectedPayment?.sepa?.bic);
    }
  }, [visible]);

  return (
    <>
      <DrawerModal visible={visible} onClose={handleCancel}>
        <div className="user-org-contents">
          <div className="user-org-title">
            {intl.formatMessage({
              id: `fleetadmin.users.payment.update`,
            })}
          </div>
          <div className="user-org-input-container">
            <div className="label">
              {intl.formatMessage({
                id: `fleetadmin.users.payment.bic`,
              })}
            </div>
            <Input
              value={bic}
              placeholder={intl.formatMessage({
                id: `fleetadmin.users.payment.bic`,
              })}
              onChange={(e) => setBIC(e.target.value)}
              style={{ marginRight: 12 }}
            />
          </div>
          <div className="button-container">
            <Button className="submit-button" onClick={handleOnSubmit}>
              {intl.formatMessage({
                id: `fleetadmin.admins.info.update`,
              })}
            </Button>
            <Button className="cancel-button" onClick={handleCancel}>
              {intl.formatMessage({
                id: `fleetadmin.admins.info.cancel`,
              })}
            </Button>
          </div>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};
export default UserBICModal;
