import { Reducer } from '../../../../@types';
import { initialState, CommonState } from './initialState';
import { CommonTypes } from '../../../../@types/enums';

export const uiReducer: Reducer<CommonState> = (
  state = initialState,
  action
) => {
  switch (action.type as any) {
    case CommonTypes.SET_APPLIED_FILTERS_HEIGHT:
      return {
        ...state,
        appliedFiltersHeight: action.payload,
      };
    case CommonTypes.SET_DISPLAY_COLUMNS:
      return {
        ...state,
        displayColumns: {
          ...state.displayColumns,
          ...action.payload,
        },
      };
    case CommonTypes.OPEN_MODAL:
      if (state.visibleModals.includes(action.payload)) {
        return state;
      }
      return {
        ...state,
        visibleModals: [...state.visibleModals, action.payload],
      };
    case CommonTypes.OPEN_CONFIRM_MODAL:
      return {
        ...state,
        confirmModal: action.payload,
      };
    case CommonTypes.SET_SELECTED_PAYMENT:
      return {
        ...state,
        selectedPayment: action.payload,
      };
    case CommonTypes.CLOSE_CONFIRM_MODAL:
      return {
        ...state,
        confirmModal: null,
      };
    case CommonTypes.OPEN_STATUS_MODAL:
      return {
        ...state,
        statusModal: action.payload,
      };
    case CommonTypes.CLOSE_STATUS_MODAL:
      return {
        ...state,
        statusModal: null,
      };
    case CommonTypes.SET_VIEW_MODE:
      return {
        ...state,
        viewMode: action.payload,
      };
    case CommonTypes.SET_VIEW_TYPE:
      return {
        ...state,
        viewType: action.payload,
      };
    case CommonTypes.CLOSE_MODAL:
      if (!state.visibleModals.includes(action.payload)) {
        return state;
      }
      return {
        ...state,
        visibleModals: state.visibleModals.filter(
          (modal) => modal !== action.payload
        ),
      };
    default:
      return state;
  }
};
