import { FleetData, BrandData } from '../../../../@types';
import { RootState } from '../../../config';

export const selectFleets = (state: RootState): Array<FleetData> | null =>
  state.appData.fleets.entities;

export const selectBrands = (state: RootState): Array<BrandData> | null =>
  state.userData.user.brands;

export const selectFleetsLoading = (state: RootState): boolean =>
  state.appData.fleets.loading;
