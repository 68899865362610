import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import TableLayout from '../../components/shared/TableLayout';
import getColumns from './getColumns';
import {
  selectSelectedClaim,
  selectDisplayClaims,
  fetchClaims,
  selectTotalClaims,
} from '../../redux/store/userData/claims';
import {
  selectClaimWithUIEffect,
  selectDisplayColumnsForClaim,
} from '../../redux/store/ui/common';
import { selectFleets } from '../../redux/store/appData/fleets';
import { useQuery } from '../../hooks/useQuery';
import { DamageEntityData } from '../../@types';
import { getFilteredColumns } from '../../utils';
import withPaginatedQueryFetch from '../../hoc/withPaginatedQueryFetch';

const ClaimTable = (props) => {
  const queries = useQuery();
  const dispatch = useDispatch();
  const intl = useIntl();
  const fleets = useSelector(selectFleets);
  const displayClaims = useSelector(selectDisplayClaims);
  const claimsNo = useSelector(selectTotalClaims);
  const selectedClaim = useSelector(selectSelectedClaim);
  const displayColumns = useSelector(selectDisplayColumnsForClaim);
  const selectedId = (selectedClaim != null && selectedClaim.id) || null;
  const columns = getColumns({ fleets, dispatch, intl });
  const filteredColumns = getFilteredColumns(columns, displayColumns);

  const onClickRow = (claim: DamageEntityData | null) => {
    dispatch(selectClaimWithUIEffect(claim));
  };

  return (
    <TableLayout
      rowKey="claim"
      columns={filteredColumns}
      dataSource={displayClaims}
      selectedId={selectedId}
      totalNumber={claimsNo}
      onClickRow={onClickRow}
      current={props.pageNumber}
      onPageChanged={props.onPageChanged}
    />
  );
};

export default withPaginatedQueryFetch(ClaimTable, fetchClaims);
