import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import { Button, Radio, Checkbox } from 'antd';
import DrawerModal from '../../components/shared/DrawerModal';
import CarStatusSelector from '../../components/CarEditDrawer/CarStatusSelector';
import BlockedReasonSelector from '../../components/CarEditDrawer/BlockedReasonSelector';
import CAR_STATUSES from '../../libs/carStatuses';
import styles from './UpdateCarStatusModal.styles';
import CircleSelector from './CircleSelector';
import {
  updateCarIfNeeded,
  selectSelectedCar,
  createStatusChangeBooking,
  getActiveBooking,
  selectTimeTableCars,
} from '../../redux/store/userData/cars';
import { fetchVehicleEventsForCar } from '../../redux/store/userData/vehicleEvents';
import { getIsVisibleModal, closeModal } from '../../redux/store/ui/common';
import { selectToken } from '../../redux/store/userData/user';
import { useIntl } from 'react-intl';
import { DatePicker } from 'antd/es';
import { isBefore } from 'date-fns';
import { RadioChangeEvent } from 'antd/lib/radio/interface';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

const THE_FAR_FUTURE = '2050-05-05T20:00:00Z';

type State = {
  nextStatus: string | undefined;
  blockedReasons?: string[];
  comment: string;
  circle: string;
};

const getRequestBodyFromState = (state: State) => {
  let body;

  if (state.nextStatus === CAR_STATUSES.RELEASE_READY) {
    body = state.comment
      ? {
          status: state.nextStatus,
          noteText: state.comment,
        }
      : {
          status: state.nextStatus,
        };
  }

  if (state.nextStatus === CAR_STATUSES.RELEASED) {
    body = state.comment
      ? {
          status: state.nextStatus,
          availableUntil: THE_FAR_FUTURE,
          noteText: state.comment,
          circles: state.circle ? [state.circle] : [],
        }
      : {
          status: state.nextStatus,
          availableUntil: THE_FAR_FUTURE,
          circles: state.circle ? [state.circle] : [],
        };
  }

  if (state.nextStatus === CAR_STATUSES.BLOCKED) {
    body = state.comment
      ? {
          status: state.nextStatus,
          blockReasons: state.blockedReasons,
          noteText: state.comment,
        }
      : {
          status: state.nextStatus,
          blockReasons: state.blockedReasons,
        };
  }

  return body;
};

const UpdateCarStatusModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const selectedCar = useSelector(selectSelectedCar);
  const displayCars = useSelector(selectTimeTableCars);

  const [state, setState] = useState<State>({
    nextStatus: selectedCar?.status,
    blockedReasons: selectedCar?.blockReasons,
    comment: '',
    circle: '',
  });
  const [isClearComment, setClearComment] = useState(false);
  const [statusChaneType, setStatusChangeType] = useState(
    selectedCar?.status === CAR_STATUSES.RENTED ? 2 : 1
  );
  const [isWithoutEndDate, setIsWithoutEndDate] = useState(false);
  const [startDate, setStartDate] = useState(null) as any;
  const [endDate, setEndDate] = useState(null) as any;

  const visibleModal = useSelector(getIsVisibleModal('updateStateModal'));
  const currentStatus = selectedCar?.status;

  useEffect(() => {
    setState({
      ...state,
      nextStatus: currentStatus,
      blockedReasons: selectedCar?.blockReasons,
    });
  }, [selectedCar, setState]);

  useEffect(() => {
    setState({
      ...state,
      comment: '',
      circle: '',
    });
    setClearComment(false);
    setStatusChangeType(selectedCar?.status === CAR_STATUSES.RENTED ? 2 : 1);
  }, [visibleModal]);

  const onSelectCarStatus = (value: any) => {
    setState({ ...state, nextStatus: value });
  };

  const onSelectReason = (value: any) => {
    setState({ ...state, blockedReasons: value });
  };

  const onSelectCircle = (value: any) => {
    setState({ ...state, circle: value });
  };

  const handleOnSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const body = getRequestBodyFromState(state);
    if (statusChaneType === 1) {
      batch(() => {
        dispatch(
          updateCarIfNeeded(
            token,
            selectedCar?.id!,
            isClearComment ? { ...body, noteText: '' } : body
          )
        );
        dispatch(closeModal('updateStateModal'));
        setTimeout(
          () => dispatch(fetchVehicleEventsForCar(selectedCar?.id!)),
          300
        );
      });
    } else {
      dispatch(
        createStatusChangeBooking(
          isWithoutEndDate
            ? {
                ...body,
                type: 'statusChange',
                requestedStatus: body?.status,
                carId: selectedCar?.id,
                ...(startDate && { from: startDate?.toISOString() }),
                statusChangeNote: body?.noteText,
                statusChangeBlockReasons: body?.blockReasons,
              }
            : {
                ...body,
                type: 'statusChange',
                requestedStatus: body?.status,
                carId: selectedCar?.id,
                from: startDate?.toISOString(),
                to: endDate?.toISOString(),
                statusChangeNote: body?.noteText,
                statusChangeBlockReasons: body?.blockReasons,
              },
          () => {
            dispatch(closeModal('updateStateModal'));
            dispatch(getActiveBooking(displayCars!.map((car) => car.id)));
          }
        )
      );
    }
  };

  const handleOnCancel = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(closeModal('updateStateModal'));
  };

  const onChangeStatusType = (e: RadioChangeEvent) => {
    setStatusChangeType(e.target.value);
  };

  const onClickWithoutEndDate = (e: CheckboxChangeEvent) => {
    setIsWithoutEndDate(e.target.checked);
  };

  const onClickClearComment = (e: CheckboxChangeEvent) => {
    setClearComment(e.target.checked);
    setState({ ...state, comment: '' });
  };

  return (
    <>
      <DrawerModal
        visible={visibleModal}
        onClose={() => {
          dispatch(closeModal('updateStateModal'));
        }}
      >
        <div
          data-testid="UpdateCarStatusModal-modalWindow"
          className="update-car-status-modal"
        >
          <div className="status-selector-wrapper">
            <p className="label">Status</p>
            <CarStatusSelector
              currentStatus={currentStatus}
              value={state.nextStatus}
              onSelect={onSelectCarStatus}
            />
          </div>
          {state.nextStatus === CAR_STATUSES.BLOCKED && (
            <div className="reason-selector-wrapper">
              <p className="label">Reason</p>
              <BlockedReasonSelector
                value={state.blockedReasons}
                onSelect={onSelectReason}
              />
            </div>
          )}
          {currentStatus !== CAR_STATUSES.RELEASED &&
            state.nextStatus === CAR_STATUSES.RELEASED &&
            selectedCar?.availableCircles?.length !== 0 &&
            selectedCar?.fleetId && (
              <div className="circle-selector-wrapper">
                <p className="label">Circle</p>
                <CircleSelector
                  fleetId={selectedCar?.fleetId}
                  value={state.circle}
                  onSelect={onSelectCircle}
                />
              </div>
            )}
          <Checkbox
            style={{ marginBottom: 8, marginTop: 8 }}
            value={isClearComment}
            onChange={onClickClearComment}
          >
            {intl.formatMessage({
              id: 'fleetadmin.car.status.clear.note',
            })}
          </Checkbox>
          <div className="status-selector-wrapper">
            <p className="label">Comment (Optional)</p>
            <textarea
              className="comment-area"
              placeholder="Type here ..."
              value={state.comment}
              onChange={(e) => setState({ ...state, comment: e.target.value })}
              draggable={false}
              disabled={isClearComment}
            >
              Type here ...
            </textarea>
          </div>

          <>
            <p className="period-label">
              {intl.formatMessage({ id: 'fleetadmin.car.status.range' })}
            </p>
            <Radio.Group onChange={onChangeStatusType} value={statusChaneType}>
              <div className="radio-wrapper">
                <div className="row" style={{ marginBottom: 16 }}>
                  <Radio
                    value={1}
                    disabled={selectedCar?.status === CAR_STATUSES.RENTED}
                  >
                    {intl.formatMessage({
                      id: 'fleetadmin.car.status.range.instant',
                    })}
                  </Radio>
                </div>
                <div className="row">
                  <Radio value={2} />
                  <div style={{ fontSize: 15, width: 35 }}>
                    {intl.formatMessage({
                      id: 'fleetadmin.car.status.range.from',
                    })}
                  </div>
                  <div style={{ marginLeft: 8, marginRight: 8, width: 180 }}>
                    <DatePicker
                      getPopupContainer={(triggerNode: HTMLElement) => {
                        return triggerNode.parentNode as HTMLElement;
                      }}
                      disabledDate={(date) =>
                        // @ts-ignore
                        isBefore(new Date(date), new Date())
                      }
                      style={{ minWidth: 'unset' }}
                      format="YYYY-MM-DD HH:mm"
                      // @ts-ignore
                      showTime={{
                        format: 'HH:mm',
                        minuteStep: 15,
                      }}
                      onChange={(value) => setStartDate(value)}
                      popupStyle={{ position: 'absolute', left: '-30%' }}
                    />
                  </div>
                </div>
                <div className="row" style={{ marginLeft: 25, marginTop: 8 }}>
                  <div style={{ fontSize: 15, width: 35 }}>
                    {intl.formatMessage({
                      id: 'fleetadmin.car.status.range.to',
                    })}
                  </div>
                  <div style={{ marginLeft: 8, marginRight: 8, width: 180 }}>
                    <DatePicker
                      getPopupContainer={(triggerNode: HTMLElement) => {
                        return triggerNode.parentNode as HTMLElement;
                      }}
                      disabled={isWithoutEndDate}
                      disabledDate={(date) =>
                        // @ts-ignore
                        isBefore(new Date(date), new Date())
                      }
                      style={{ minWidth: 'unset' }}
                      format="YYYY-MM-DD HH:mm"
                      // @ts-ignore
                      showTime={{
                        format: 'HH:mm',
                        minuteStep: 15,
                      }}
                      onChange={(value) => setEndDate(value)}
                      popupStyle={{ position: 'absolute', left: '-30%' }}
                    />
                  </div>
                  <Checkbox onChange={onClickWithoutEndDate}>
                    {intl.formatMessage({
                      id: 'fleetadmin.car.status.range.without_end_date',
                    })}
                  </Checkbox>
                </div>
              </div>
            </Radio.Group>
          </>
          <Button
            data-testid="UpdateCarStatusModal-submitBtn"
            className="submit-button"
            onClick={handleOnSubmit}
            disabled={
              (state.nextStatus === CAR_STATUSES.BLOCKED &&
                state?.blockedReasons?.length === 0) ||
              (currentStatus === state.nextStatus &&
                currentStatus !== CAR_STATUSES.BLOCKED &&
                state.nextStatus !== CAR_STATUSES.BLOCKED &&
                !isClearComment)
            }
          >
            {intl.formatMessage({
              id: `submit`,
            })}
          </Button>
          <Button className="cancel-button" onClick={handleOnCancel}>
            {intl.formatMessage({
              id: `cancel`,
            })}
          </Button>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};

export default UpdateCarStatusModal;
