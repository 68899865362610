import React from 'react';
import styles from './UserTimeline.styles';
import { useIntl } from 'react-intl';
import TimelineItem from '../../TimeLine/TimelineItem';
import UserFileUpload from './UserFileUpload';

const UserTimeline = ({ selectedUser }) => {
  const intl = useIntl();

  return (
    <>
      <UserFileUpload selectedUser={selectedUser} />
      <div className="user-timeline">
        {[...(selectedUser?.notes || [])].reverse().map((noteEvent, i) => (
          <TimelineItem
            key={`vehicleEvents-${i}`}
            vehicleEvent={{
              type: noteEvent?.file ? 'documentAdded' : 'noteAdded',
              author: noteEvent?.operator,
              addedAt: noteEvent?.timestamp,
              ...noteEvent,
            }}
          />
        ))}
        {selectedUser?.notes?.length === 0 && (
          <div className="empty-wrapper">
            <img
              className="empty"
              id="eyeIcon"
              src="/images/table/empty.svg"
              width="50"
              height="50"
            />
            {intl.formatMessage({
              id: `fleetadmin.users.info.empty`,
            })}
          </div>
        )}
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default UserTimeline;
