import React, { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import DrawerModal from '../../components/shared/DrawerModal';
import styles from './UpdateClaimNoteModal.styles';
import { getIsVisibleModal, closeModal } from '../../redux/store/ui/common';
import { colors } from '../../theme/theme';
import { updateClaimIfNeeded } from '../../redux/store/userData/claims';
import { selectToken } from '../../redux/store/userData/user/selectors';
import { selectSelectedClaim } from '../../redux/store/userData/claims/selectors';
import { useIntl } from 'react-intl';

const UpdateClaimNoteModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const selectedClaim = useSelector(selectSelectedClaim);
  const visibleModal = useSelector(getIsVisibleModal('updateClaimNoteModal'));
  const [note, setNote] = useState('');

  useEffect(() => {
    setNote('');
  }, [visibleModal]);

  const handleOnSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    batch(() => {
      dispatch(
        updateClaimIfNeeded(selectedClaim?.id!, {
          note,
        })
      );
    });
    dispatch(closeModal('updateClaimNoteModal'));
  };

  const handleOnCancel = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(closeModal('updateClaimNoteModal'));
  };

  return (
    <>
      <DrawerModal
        visible={visibleModal}
        onClose={() => {
          dispatch(closeModal('updateClaimNoteModal'));
        }}
      >
        <div className="update-car-area-modal">
          <div className="status-selector-wrapper">
            <p className="label">Claim note</p>
            <textarea
              className="comment-area"
              placeholder="Type here ..."
              value={note}
              onChange={(e) => setNote(e.target.value)}
              draggable={false}
            >
              Type here ...
            </textarea>
          </div>
          <Button
            className="submit-button"
            onClick={handleOnSubmit}
            disabled={!note}
            style={!note ? { background: colors.buttonDisabled } : {}}
          >
            {intl.formatMessage({
              id: `submit`,
            })}
          </Button>
          <Button className="cancel-button" onClick={handleOnCancel}>
            {intl.formatMessage({
              id: `cancel`,
            })}
          </Button>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};

export default UpdateClaimNoteModal;
