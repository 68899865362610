import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CAR_STATUSES from '../../libs/carStatuses';
import CarStatus from '../../components/shared/CarStatus';
import CarConditions from './CarConditions';
import UnlockLockButton from './UnlockLockButton';
import styles from './StatusInfo.styles';
import ListEditItem from '../../components/shared/ListEditItem';
import LinkToGoogleMap from '../../components/shared/LinkToGoogleMap';
import LinkToArea from '../../components/shared/LinkToArea';
import getFleetNameById from '../../utils/getFleetNameById';
import { getAreaById } from '../../utils';
import { useIntl } from 'react-intl';
import { notification, Popover } from 'antd';
import { subMinutes } from 'date-fns';
import {
  selectSelectedCar,
  selectUpdatingCarStatus,
  selectLastCarsStatus,
} from '../../redux/store/userData/cars';
import { selectAreas } from '../../redux/store/appData/areas';
import { openModal } from '../../redux/store/ui/common';
import { selectFleets } from '../../redux/store/appData/fleets';
import DeviceFunctions from '../../components/CarEditDrawer/DeviceFunctions';
import ListItem from '../../components/shared/ListItem';
import LinkToCategory from '../shared/LinkToCategory';
import { selectCategories } from '../../redux/store/userData/categories';
import formatDateTime from '../../utils/formatDateTime';
import { updateVehicleProfileModal } from './UpdateVehicleProfileModal';
import LinkToProfile from '../shared/LinkToProfile';
import { selectProfiles } from '../../redux/store/userData/profiles';
import { selectAuth } from '../../redux/store/userData/user/selectors';

const StatusInfo = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectAuth);
  const fleets = useSelector(selectFleets);
  const selectedCar = useSelector(selectSelectedCar);
  const areas = useSelector(selectAreas);
  const categories = useSelector(selectCategories);
  const profiles = useSelector(selectProfiles);
  const lastCarsStatus = useSelector(selectLastCarsStatus);
  const area = getAreaById(areas, selectedCar?.areaId!);
  const updatingStatus = useSelector(selectUpdatingCarStatus);
  const [updatingArea, setUpdatingArea] = useState(false);
  const isValidAllConditions = true;
  const isEditableConnection =
    user?.permissions?.fleetManager &&
    // @ts-ignore
    selectedCar?.supportedVehicleConnections?.length > 1 &&
    ![CAR_STATUSES.RELEASED, CAR_STATUSES.RENTED].includes(
      selectedCar?.status!
    );
  const isEditableStatus =
    user?.permissions?.fleetManager &&
    (selectedCar?.status === CAR_STATUSES.RELEASE_READY ||
      selectedCar?.status === CAR_STATUSES.RELEASED ||
      selectedCar?.status === CAR_STATUSES.RENTED ||
      selectedCar?.status === CAR_STATUSES.BLOCKED);

  const statusNote = selectedCar?.statusNote || null;
  const fallbackStatusComment =
    selectedCar && selectedCar?.notes
      ? [...selectedCar?.notes]
          .reverse()
          .find(
            (note) =>
              new Date(note?.timestamp!) >
              subMinutes(new Date(selectedCar?.statusChangedAt!), 2)
          )?.text
      : null;

  const carProfile = useMemo(
    () => profiles?.find((profile) => profile.id === selectedCar?.profileId),
    [profiles, selectedCar?.profileId]
  );

  useEffect(() => {
    if (updatingArea && !updatingStatus) {
      setUpdatingArea(false);
    }
  }, [updatingArea, updatingStatus]);

  const handleClickEditButton = (e: any) => {
    e.preventDefault();
    dispatch(openModal('updateStateModal'));
  };

  const handleCategoryEditButton = (e: any) => {
    e.preventDefault();
    dispatch(openModal('updateCategoryModal'));
  };

  const handleAreaEditButton = (e: any) => {
    e.preventDefault();
    dispatch(openModal('updateAreaModal'));
  };

  const handleProfileEditButton = (e: any) => {
    e.preventDefault();
    dispatch(openModal(updateVehicleProfileModal));
  };

  const handleConnectionEditButton = (e: any) => {
    e.preventDefault();
    dispatch(openModal('updateConnectionModal'));
  };

  const copyLocation = () => {
    const el = document.createElement('textarea');
    // @ts-ignore
    el.value = selectedCar.position;
    document.body.appendChild(el);
    el.focus();
    el.select();
    try {
      document.execCommand('copy');
      document.body.removeChild(el);
      notification.success({
        message: 'Location Copied',
        style: {
          whiteSpace: 'pre',
        },
      });
    } catch (err) {
      console.log('Oops, unable to copy');
    }
  };

  const onTerminateVehicle = () => {
    dispatch(openModal('TerminateCarModal'));
  };

  const intl = useIntl();

  return (
    <div style={{ paddingBottom: '75px' }}>
      <div className="wrapper">
        <CarConditions />
        {[
          CAR_STATUSES.RELEASE_READY,
          CAR_STATUSES.TERMINATED,
          CAR_STATUSES.BLOCKED,
        ].includes(selectedCar?.status!) &&
          user?.permissions?.fleetManager &&
          isValidAllConditions &&
          selectedCar?.vehicleConnection === 'cloudboxx' && (
            <UnlockLockButton />
          )}
      </div>
      <ListEditItem
        testid="status"
        label="Status"
        leftItem={
          <div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <CarStatus
                status={selectedCar?.status}
                style={{
                  width: '130px',
                  height: '24px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: '3px',
                }}
              />
              {selectedCar?.status === CAR_STATUSES.BLOCKED &&
                selectedCar?.blockReasons!.length > 0 &&
                selectedCar?.blockReasons?.map((reason) => (
                  <Popover
                    key={reason}
                    content={
                      <div className="popover">
                        {intl.formatMessage({
                          id: `fleetadmin.blockReasons.${reason}`,
                        })}
                      </div>
                    }
                    placement="top"
                  >
                    <img
                      src={`/images/blocked-reasons/${reason}.svg`}
                      alt={reason}
                      className="block-reason-icon"
                    />
                  </Popover>
                ))}
            </div>
            {(statusNote || fallbackStatusComment) && (
              <p className="status-comment">{`"${
                statusNote || fallbackStatusComment
              }"`}</p>
            )}
          </div>
        }
        rightItem={
          isEditableStatus ? (
            <img
              data-testid="StatusInfo-editStatusBtn"
              className="block-edit-button"
              src={'/images/drawer/edit.svg'}
              alt="edit"
              onClick={handleClickEditButton}
            />
          ) : null
        }
      />
      <ListEditItem
        testid="fleet"
        label="Fleet"
        leftItem={<span>{getFleetNameById(fleets, selectedCar?.fleetId)}</span>}
        rightItem={<></>}
      />
      <ListEditItem
        testid="category"
        label="Category"
        leftItem={
          <LinkToCategory
            category={
              categories?.find(
                (category) => category.id === selectedCar?.categoryId
              ) || null
            }
            text={
              categories?.find(
                (category) => category.id === selectedCar?.categoryId
              )?.name || 'Not assigned'
            }
          />
        }
        rightItem={
          user?.permissions?.fleetManager &&
          !['RELEASED', 'RENTED'].includes(selectedCar?.status!) && (
            <img
              className="edit-button"
              src={'/images/drawer/edit.svg'}
              alt="edit"
              onClick={handleCategoryEditButton}
            />
          )
        }
      />
      <ListEditItem
        testid="profile"
        label="Profile"
        leftItem={
          <LinkToProfile
            profile={carProfile}
            text={carProfile?.name || 'Not assigned'}
          />
        }
        rightItem={
          user?.permissions?.fleetManager &&
          !['RENTED'].includes(selectedCar?.status!) && (
            <img
              className="edit-button"
              src={'/images/drawer/edit.svg'}
              alt="edit"
              onClick={handleProfileEditButton}
            />
          )
        }
      />
      <ListEditItem
        testid="area"
        label="Area"
        leftItem={<LinkToArea area={area} text={area?.name} />}
        rightItem={
          user?.permissions?.fleetManager && (
            <img
              data-testid="StatusInfo-carAreaEditBtn"
              className="edit-button"
              src={'/images/drawer/edit.svg'}
              alt="edit"
              onClick={handleAreaEditButton}
            />
          )
        }
      />
      {(lastCarsStatus[selectedCar?.id!]?.position ||
        selectedCar?.position) && (
        <ListEditItem
          testid="location"
          label="Location"
          leftItem={
            <LinkToGoogleMap
              position={
                lastCarsStatus[selectedCar?.id!]?.position ||
                selectedCar?.position
              }
            />
          }
          rightItem={
            <div className="car-position-right-wrapper">
              <div className="car-position-last-update">
                {lastCarsStatus[selectedCar?.id!]?.positionLastUpdate
                  ? formatDateTime(
                      lastCarsStatus[selectedCar?.id!]?.positionLastUpdate
                    )
                  : ''}
              </div>
              <img
                src={'/images/car-editor/location.svg'}
                alt="location"
                onClick={copyLocation}
                style={{ cursor: 'pointer' }}
              />
            </div>
          }
        />
      )}
      <ListEditItem
        testid="accesskit-type"
        label="AccessKit Type"
        leftItem={<span>{selectedCar?.vehicleConnection}</span>}
        rightItem={
          isEditableConnection ? (
            <img
              className="edit-button"
              src={'/images/drawer/edit.svg'}
              alt="edit"
              onClick={handleConnectionEditButton}
            />
          ) : null
        }
      />
      <ListItem
        testid="accesskit-no"
        label="AccessKit No"
        value={
          <div className="kit-wrapper">
            <div>
              {selectedCar?.vehicleConnection === 'geoTab'
                ? selectedCar?.geotabSNR
                : selectedCar?.deviceSNR}
            </div>
          </div>
        }
      />
      {user?.permissions?.fleetManager &&
        [
          'EQUIPPED',
          'RELEASE_READY',
          'RELEASED',
          'BLOCKED',
          'RENTED',
          'SERVICE',
        ].includes(selectedCar?.status!) &&
        selectedCar?.vehicleConnection === 'cloudboxx' && <DeviceFunctions />}
      {user?.permissions?.fleetManager &&
        ['RELEASE_READY', 'BLOCKED'].includes(selectedCar?.status!) && (
          <div className="car-terminate-button" onClick={onTerminateVehicle}>
            Terminate vehicle
          </div>
        )}
      <style jsx>{styles}</style>
    </div>
  );
};

export default StatusInfo;
