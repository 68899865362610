import { CommonTypes } from '../../../../@types/enums';
import { createAction } from '../../../helpers';
import { StatusModal } from './initialState';

export const setAppliedFiltersHeight = (payload: number) =>
  createAction(CommonTypes.SET_APPLIED_FILTERS_HEIGHT, payload);

type DisplayColumns = {
  [target: string]: string[];
};

export const setDisplayColumns = (payload: DisplayColumns) =>
  createAction(CommonTypes.SET_DISPLAY_COLUMNS, payload);

export const openModal = (payload: string) =>
  createAction(CommonTypes.OPEN_MODAL, payload);

export const closeModal = (payload: string) =>
  createAction(CommonTypes.CLOSE_MODAL, payload);

export const openConfirmModal = (payload: {
  title: string;
  onConfirm: () => void;
  destructive?: boolean;
}) => createAction(CommonTypes.OPEN_CONFIRM_MODAL, payload);

export const openStatusModal = (payload: StatusModal) =>
  createAction(CommonTypes.OPEN_STATUS_MODAL, payload);

export const closeConfirmModal = () =>
  createAction(CommonTypes.CLOSE_CONFIRM_MODAL);

export const closeStatusModal = () =>
  createAction(CommonTypes.CLOSE_STATUS_MODAL);

export const setViewMode = (payload: string) =>
  createAction(CommonTypes.SET_VIEW_MODE, payload);

export const setViewType = (payload: string) =>
  createAction(CommonTypes.SET_VIEW_TYPE, payload);

export const setSelectedPayment = (payload: string) =>
  createAction(CommonTypes.SET_SELECTED_PAYMENT, payload);
