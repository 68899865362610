import React, { ReactNode, MouseEvent } from 'react';
import styles from './ListItem.styles';
import LinkToGoogleMap from '../../components/shared/LinkToGoogleMap';
import { claimInfoStyles } from '../ClaimDrawer/ClaimInfo.styles';

type PropsType = {
  label?: ReactNode;
  value: ReactNode;
  onMouseEnter?: (e: MouseEvent) => void;
  onMouseLeave?: (e: MouseEvent) => void;
  onClick?: (e: MouseEvent) => void;
  locationType?: boolean;
  style?: any;
  valueStyles?: any;
  testid?: string;
};

const ListItem: React.FC<PropsType> = ({
  label,
  value,
  onMouseEnter,
  onMouseLeave,
  onClick,
  locationType,
  style = {},
  valueStyles = {},
  testid,
}) => {
  return (
    <>
      <div
        className="list-item-wrapper"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        style={style}
      >
        {label && (
          <p data-testid={`ListItem-label-${testid}`} className="label">
            {label}
          </p>
        )}
        {locationType ? (
          // @ts-ignore
          <LinkToGoogleMap position={value} />
        ) : (
          <div className="value-wrapper" style={valueStyles}>
            {value || '-'}
          </div>
        )}
      </div>
      <style jsx>{styles}</style>
      <style jsx>{claimInfoStyles}</style>
    </>
  );
};

export default ListItem;
