import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import getColumns from './getColumns';
import TableLayout from '../../components/shared/TableLayout';
import { AreaData } from '../../@types';
// @ts-ignore
import cookie from 'js-cookie';
import { fetchCarsIfNeeded, selectCars } from '../../redux/store/userData/cars';
import {
  fetchAreasIfNeeded,
  selectSelectedArea,
  selectAreas,
  selectDisplayAreas,
} from '../../redux/store/appData/areas';
import {
  selectDisplayColumnsForArea,
  selectAreaWithUIEffect,
} from '../../redux/store/ui/common';
import { selectFleets } from '../../redux/store/appData/fleets';
import { getFilteredColumns } from '../../utils';

const AreaTable = () => {
  const dispatch = useDispatch();
  const cars = useSelector(selectCars);
  const displayAreas: AreaData[] | null = useSelector(selectDisplayAreas);
  const fleets = useSelector(selectFleets);
  const areasEntities = useSelector(selectAreas);
  const carsEntities = useSelector(selectCars);
  const selectedArea: AreaData | null = useSelector(selectSelectedArea);
  const displayColumns = useSelector(selectDisplayColumnsForArea);

  const selectedId = selectedArea ? selectedArea.id : null;
  const columns = getColumns({ fleets, cars, dispatch });
  const filteredColumns = getFilteredColumns(columns, displayColumns);

  const onClickRow = (record: AreaData) => {
    dispatch(selectAreaWithUIEffect(record));
  };

  const loadAreas = useCallback(async () => {
    dispatch(fetchCarsIfNeeded());
    dispatch(fetchAreasIfNeeded());
  }, []);

  useEffect(() => {
    if (!areasEntities && !carsEntities) {
      loadAreas();
    }
  }, []);

  return (
    <TableLayout
      rowKey="area"
      columns={filteredColumns}
      dataSource={displayAreas}
      selectedId={selectedId}
      totalNumber={displayAreas?.length}
      onClickRow={onClickRow}
    />
  );
};

export default AreaTable;
