import { Target } from '../@types';

const targetsForReceipts: Target[] = [
  {
    value: 'brandName',
    type: 'text',
    isAPI: true,
    queryName: 'brands',
    queryType: 'array',
  },
  { value: 'receiptNo', type: 'text', isAPI: true, queryName: 'receiptNo' },
  {
    value: 'receiptMerchantReference',
    type: 'text',
    isAPI: true,
    queryName: 'merchantReference',
  },
  {
    value: 'receiptType',
    type: 'selection',
    isAPI: true,
    queryName: 'receiptType',
  },
  {
    value: 'userCode',
    type: 'text',
    isAPI: true,
    queryName: 'userReferralCode',
  },
  {
    value: 'tripCode',
    type: 'text',
    isAPI: true,
    queryName: 'presentableRentalId',
  },
  {
    value: 'receiptDate',
    type: 'date',
    isAPI: true,
    queryNameFrom: 'dateFrom',
    queryNameTo: 'dateTo',
  },
  { value: 'collection', type: 'text', isAPI: true },
  { value: 'createdBy', type: 'text', isAPI: true },
  { value: 'vatRate', type: 'number', isAPI: false },
  { value: 'vatAmount', type: 'number', isAPI: false },
  { value: 'grossAmount', type: 'number', isAPI: false },
];

export default targetsForReceipts;
