import React from 'react';
import { useSelector } from 'react-redux';
import styles from './NumOfResults.styles';
import { selectViewMode } from '../../redux/store/ui/common/selectors';
import {
  useDisplayColumns,
  useDisplayViewMode,
} from '../../hooks/useDisplayViewMode';
import { CSVLink } from 'react-csv';
import { ExportOutlined } from '@ant-design/icons';
import moment from 'moment';
import { ViewMode } from '../../hooks/useViewMode';
import { formatPrice } from '../../utils';
import fixGeoJSONCoordinates from '../../utils/fixGeoJSONCoordinates';
import getBoundsFromPoints from '../../utils/getBoundsFromPoints';

const moneyKeys = ['vatRate', 'vatAmount', 'grossAmount'];

const getCenterFromGeoJson = (geoJsonData: any) => {
  const geoJson = fixGeoJSONCoordinates(geoJsonData);
  const bounds = getBoundsFromPoints(
    // @ts-ignore
    geoJson.features[0].geometry.coordinates[0]
  );
  const center = {
    lat: (bounds.north + bounds.south) / 2,
    lng: (bounds.east + bounds.west) / 2,
  };

  return `${center.lat?.toString()?.slice(0, 10)},${center.lng
    ?.toString()
    ?.slice(0, 10)}`;
};

const flattenDataForCSV = (
  data,
  displayColumns = [],
  columnsToExclude = []
) => {
  if (!data || data.length === 0) {
    return [];
  }

  const convertToEuroFormat = (key, value) => {
    if (moneyKeys.includes(key)) {
      return formatPrice(value, false, true);
    }
    return value;
  };

  const flatten = (obj, parentKey = '', result = {}) => {
    Object.keys(obj).forEach((key) => {
      if (columnsToExclude.includes(parentKey ? `${parentKey}.${key}` : key)) {
        return;
      }
      const newKey = parentKey ? `${parentKey}.${key}` : key;
      let value = obj[key];

      if (key === 'geoJSONData' && value) {
        // Replace key with 'location' and value with center coordinates
        result['location'] = getCenterFromGeoJson(value);
        return;
      }

      if (typeof value === 'object' && value !== null) {
        if (Array.isArray(value)) {
          value.forEach((item, index) => {
            const itemKey = `${newKey}.[${index}]`;
            if (typeof item === 'object' && item !== null) {
              flatten(item, itemKey, result);
            } else {
              result[itemKey] = convertToEuroFormat(itemKey, item);
            }
          });
        } else {
          flatten(value, newKey, result);
        }
      } else {
        result[newKey] = convertToEuroFormat(newKey, value);
      }
    });

    return result;
  };

  const flattenedData = Array.isArray(data)
    ? data.map((item) => flatten(item))
    : [flatten(data)];

  if (displayColumns.length > 0) {
    return flattenedData.map((item) => {
      const orderedItem = {};
      displayColumns.forEach((column) => {
        if (Object.prototype.hasOwnProperty.call(item, column)) {
          orderedItem[column] = item[column];
        }
      });
      return orderedItem;
    });
  }

  return flattenedData;
};

const PIIExcludeByViewMode = {
  [ViewMode.USERS]: [
    'firstName',
    'lastName',
    'email',
    'phoneNumber',
    'dateOfBirth',
    'license.number',
  ],
};

const ExportCSV: React.FC = () => {
  const viewMode = useSelector(selectViewMode);
  const filteredArray = useDisplayViewMode(viewMode);
  const displayColumns = useDisplayColumns(viewMode);
  const piiToExclude = PIIExcludeByViewMode[viewMode] || [];
  const csvData = flattenDataForCSV(
    filteredArray,
    displayColumns,
    piiToExclude
  );

  return viewMode !== ViewMode.PRIVACY_POLICY && filteredArray?.length > 0 ? (
    <>
      <CSVLink
        data-testid="ExportCSV-button"
        filename={`${viewMode}_Table_${moment().format('YYYY_MM_DD')}.csv`}
        data={csvData}
        className="btn btn-primary"
        style={{ fontSize: 12 }}
      >
        <ExportOutlined style={{ marginRight: 3 }} />
        Export (CSV)
      </CSVLink>
      <style jsx>{styles}</style>
    </>
  ) : null;
};

export default ExportCSV;
