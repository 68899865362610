import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import FilterPanel from './FilterPanel';
import styles from './AddFilter.styles';
import isDesktop from '../../utils/sizeHelpers';
import {
  selectIsFilterPanelOpen,
  selectFilterTarget,
  openFilterPanel,
  closeFilterPanel,
} from '../../redux/store/ui/filters';
import { selectViewMode } from '../../redux/store/ui/common/selectors';

const FILTER_ICON = '/images/filter/filter.svg';

type AddFilterProps = {};

const AddFilter: React.FC<AddFilterProps> = () => {
  const node = useRef(null);
  const dispatch = useDispatch();
  const intl = useIntl();
  const viewMode = useSelector(selectViewMode);
  const isPanelOpen = useSelector(selectIsFilterPanelOpen);
  const target = useSelector(selectFilterTarget);

  const handleOnClick = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (isPanelOpen) {
      dispatch(closeFilterPanel());
    } else {
      dispatch(openFilterPanel());
    }
  };

  const handleOnClickOutSide = (e: any) => {
    const isClickedInside =
      // @ts-ignore
      node.current.contains(e.target) ||
      (typeof e.target.className === 'string' &&
        e.target.className.includes('dropdown')) ||
      (typeof e.target.className === 'string' &&
        e.target.className.includes('calendar')) ||
      (typeof e.target.className === 'string' &&
        e.target.className.includes('ant-select-item'));

    if (!isClickedInside) {
      dispatch(closeFilterPanel());
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOnClickOutSide);
    return () => {
      document.removeEventListener('mousedown', handleOnClickOutSide);
    };
  }, []);

  return (
    <>
      <div className="add-filter-wrapper" ref={node}>
        <div
          data-testid="AddFilter-button"
          className="add-filter-button"
          onClick={handleOnClick}
        >
          <img className="filter-icon" src={FILTER_ICON} alt="filter icon" />
          <p className="button-text">
            {target
              ? intl.formatMessage({
                  id: `fleetadmin.filter.${viewMode}.${target}`,
                })
              : isDesktop()
              ? 'Add filters'
              : ''}
          </p>
        </div>
        {isPanelOpen && (
          <div className="panel-wrapper">
            <FilterPanel />
          </div>
        )}
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default AddFilter;
