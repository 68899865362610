import React, { useState, ReactNode } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Button } from 'antd';
import { ViewMode } from '../../hooks/useViewMode';
import getAreaNameById from '../../utils/getAreaNameById';
import getFleetNameById from '../../utils/getFleetNameById';
import Selection from './Selection';
import BooleanSelection from './BooleanSelection';
import TextSearch from './TextSearch';
import NumberSearch from './NumberSearch';
import Number from './Number';
import DateSearch from './DateSearch';
import getDataSourceValues from './helpers/getDataSourceValues';
import getOptionValues from './helpers/getOptionValues';
import styles from './ValueSelector.styles';
import { selectCares } from '../../redux/store/userData/cares';
import { selectAreas } from '../../redux/store/appData/areas';
import {
  selectArchivedCars,
  selectCars,
  selectSelectedVehiclesSubmenu,
} from '../../redux/store/userData/cars';
import { selectClaims } from '../../redux/store/userData/claims';
import { selectTrips, selectBookings } from '../../redux/store/userData/trips';
import { selectCircles } from '../../redux/store/userData/circles';
import { selectFleets } from '../../redux/store/appData/fleets';
import {
  selectFilter,
  selectFilterTargetType,
  selectFilterTarget,
  closeFilterPanel,
  addAndApplyFilter,
} from '../../redux/store/ui/filters';
import { selectCategories } from '../../redux/store/userData/categories';
import { selectPricings } from '../../redux/store/appData/pricings';
import { labelForCircle } from '../../utils';
import { selectViewMode } from '../../redux/store/ui/common';
import { selectProfiles } from '../../redux/store/userData/profiles';
import { selectTariffs, selectUsers } from '../../redux/store/userData/users';
import { selectAdmins } from '../../redux/store/userData/admins';
import { selectReceipts } from '../../redux/store/userData/receipts';
import { selectPrivacyPolicies } from '../../redux/store/appData/privacyPolicy';

type ValueSelectorProps = {};

const ValueSelector: React.FC<ValueSelectorProps> = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [value, setValue] = useState(null) as any;
  const [isMoreThan, setIsMoreThan] = useState(true);
  const onChangeRadio = () => setIsMoreThan(!isMoreThan);
  const [fromDate, setFromDate] = useState(null);
  const [untilDate, setUntilDate] = useState(null);
  const target = useSelector(selectFilterTarget);
  const targetType = useSelector(selectFilterTargetType);
  const cars = useSelector(selectCars);
  const archivedCars = useSelector(selectArchivedCars);
  const areas = useSelector(selectAreas);
  const fleets = useSelector(selectFleets);
  const circles = useSelector(selectCircles);
  const claims = useSelector(selectClaims);
  const cares = useSelector(selectCares);
  const trips = useSelector(selectTrips);
  const bookings = useSelector(selectBookings);
  const categories = useSelector(selectCategories);
  const pricings = useSelector(selectPricings);
  const privacyPolicies = useSelector(selectPrivacyPolicies);
  const profiles = useSelector(selectProfiles);
  const users = useSelector(selectUsers);
  const admins = useSelector(selectAdmins);
  const receipts = useSelector(selectReceipts);
  const filter = useSelector(selectFilter);
  const submenu = useSelector(selectSelectedVehiclesSubmenu);
  const viewMode = useSelector(selectViewMode);
  const tariffs = useSelector(selectTariffs);
  const tariffIds = tariffs
    ?.filter((itm) => !itm?.archived)
    ?.map((tariff) => tariff?.id);

  let body: ReactNode;

  if (targetType === 'selection') {
    const fleetIds = fleets?.map((fleet) => fleet.id) || [];
    const brands = [
      // @ts-ignore
      ...new Set(fleets?.map((item) => item.brandId)),
    ].map((brand) => ({
      id: brand,
      name: fleets?.find((fleet) => fleet?.brandId === brand)?.brandName,
    }));
    const brandIds = brands?.map((brand) => brand.id) || [];
    const optionValues =
      target === 'tariff'
        ? tariffIds
        : target === 'fleetId'
        ? fleetIds
        : target === 'brandId'
        ? brandIds
        : getOptionValues(viewMode, target);
    const options = optionValues.map((option: string | undefined) => {
      const label =
        target === 'tariff'
          ? tariffs?.find((tariff) => tariff?.id === option)?.name
          : target === 'fleetId'
          ? getFleetNameById(fleets, option)
          : target === 'brandId'
          ? brands.find((brand) => brand.id === option)?.name
          : viewMode === ViewMode.RECEIPTS && target === 'receiptType'
          ? intl.formatMessage({
              id: `fleetadmin.receipts.type.${option}`,
            })
          : viewMode === ViewMode.VEHICLE && target === 'status'
          ? intl.formatMessage({ id: `fleetadmin.carStatus.${option}` })
          : viewMode === ViewMode.VEHICLE && target === 'blockReasons'
          ? intl.formatMessage({ id: `fleetadmin.blockReasons.${option}` })
          : viewMode === ViewMode.USERS &&
            (target === 'blockReason' || target === 'blockReasonDetails')
          ? intl.formatMessage({ id: `fleetadmin.blockReasons.${option}` })
          : viewMode === ViewMode.CARE && target === 'status'
          ? intl.formatMessage({ id: `fleetadmin.serviceStatus.${option}` })
          : viewMode === ViewMode.SHARE && target === 'status'
          ? intl.formatMessage({ id: `fleetadmin.status.${option}` })
          : viewMode === ViewMode.USERS &&
            [
              'accountStatus',
              'creditworthinessData.creditWorthinessStatus',
              'identityDocuments.[0].status',
              'licenses.[0].status',
              'userType',
            ].includes(target)
          ? intl.formatMessage({
              id:
                target === 'userType'
                  ? `fleetadmin.user.${option}`
                  : `fleetadmin.status.${option}`,
            })
          : option;

      return {
        label,
        value: option,
        disabled: false,
      };
    });

    body = (
      <Selection
        options={options}
        setValue={(value) => {
          if (viewMode === ViewMode.CLAIM && value[0] === 'inProgress') {
            setValue(['confirmed', 'completed']);
          } else {
            setValue(value);
          }
        }}
        // @ts-ignore
        defaultValue={filter[viewMode][target]}
      />
    );
  } else if (targetType === 'text') {
    const entities =
      viewMode === ViewMode.VEHICLE
        ? submenu === 'archived'
          ? archivedCars
          : cars
        : viewMode === ViewMode.AREA
        ? areas
        : viewMode === ViewMode.CLAIM
        ? claims
        : viewMode === ViewMode.CARE
        ? cares
        : viewMode === ViewMode.SHARE
        ? trips
        : viewMode === ViewMode.BOOKING
        ? bookings
        : viewMode === ViewMode.CATEGORIES
        ? categories
        : viewMode === ViewMode.PRICING
        ? pricings
        : viewMode === ViewMode.CIRCLE
        ? circles
        : viewMode === ViewMode.PROFILE
        ? profiles
        : viewMode === ViewMode.USERS
        ? users
        : viewMode === ViewMode.ADMINS
        ? admins
        : viewMode === ViewMode.RECEIPTS
        ? receipts
        : viewMode === ViewMode.PRIVACY_POLICY
        ? privacyPolicies
        : [];

    const dataSourceValues = getDataSourceValues(entities, target);
    const dataSource = dataSourceValues.filter(Boolean).map((value: any) => {
      const text =
        target === 'areaId'
          ? getAreaNameById(areas, value)
          : target === 'circleType'
          ? labelForCircle(value)
          : target === 'userType'
          ? intl.formatMessage({ id: `fleetadmin.user.${target}.${value}` })
          : viewMode === ViewMode.PRIVACY_POLICY && target === 'status'
          ? intl.formatMessage({ id: `fleetadmin.status.${value}` })
          : value;
      return {
        value,
        text,
      };
    });

    body = <TextSearch dataSource={dataSource} setValue={setValue} />;
  } else {
    body =
      targetType === 'boolean' ? (
        <BooleanSelection
          setValue={setValue}
          // @ts-ignore
          defaultValue={filter[viewMode][target]}
        />
      ) : targetType === 'number' ? (
        <NumberSearch
          isMoreThan={isMoreThan}
          onChangeValue={setValue}
          onChangeRadio={onChangeRadio}
        />
      ) : targetType === 'int' ? (
        <Number onChangeValue={setValue} />
      ) : targetType === 'date' ? (
        <DateSearch setFromDate={setFromDate} setUntilDate={setUntilDate} />
      ) : null;
  }

  const onSubmit = () => {
    console.log('value', value);
    let valueToSend = value;
    if (value !== null && targetType === 'number') {
      valueToSend = isMoreThan ? `>${value}` : `<${value}`;
    } else if (targetType === 'date') {
      if (fromDate && untilDate) {
        valueToSend = `${fromDate}_${untilDate}`;
      } else if (fromDate) {
        valueToSend = `after_${fromDate}`;
      } else if (untilDate) {
        valueToSend = `before_${untilDate}`;
      }
    }
    batch(() => {
      if (valueToSend || targetType === 'int') {
        dispatch(
          addAndApplyFilter({
            viewMode,
            key: target,
            value: valueToSend.toString(),
          })
        );
      }
      dispatch(closeFilterPanel());
    });
  };

  return (
    <>
      <div className="value-selector">
        <div className="body">{body}</div>
        <div className="footer">
          <Button
            className="add-filter-button"
            onClick={onSubmit}
            disabled={
              value === null && targetType !== 'int' && !fromDate && !untilDate
            }
          >
            Add Filter
          </Button>
        </div>
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

export default ValueSelector;
