import React, { useRef } from 'react';
import { Empty, Skeleton } from 'antd';
import styles from './RecordTable.styles';
import isDesktop from '../../utils/sizeHelpers';
import ClaimInfo from '../ClaimDrawer/ClaimInfo';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import {
  selectIsLocadingCarClaims,
  selectSelectedClaim,
} from '../../redux/store/userData/claims/selectors';

type RecordTableProps = {
  intl: any;
  rowKey: string;
  dataSource: any[];
  selectedId?: string;
  onClickRow: Function;
};

const headCells = [
  { id: 'index', label: 'index', numeric: true },
  { id: 'where', label: 'damageWhere', numeric: false },
  { id: 'location', label: 'damageLocation', numeric: false },
  { id: 'side', label: 'damageSide', numeric: false },
  { id: 'component', label: 'damageComponent', numeric: false },
];

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#f2f2f2',
    color: '#000',
    fontFamily: 'AvenirNext,sans-serif',
  },
  body: {
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.65)',
    fontFamily: 'AvenirNext,sans-serif',
  },
}))(TableCell);

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function descendingComparator(
  a: { [x: string]: number },
  b: { [x: string]: number },
  orderBy: string | number,
  order: string
) {
  // equal items sort equally
  if (a[orderBy] === b[orderBy]) {
    return 0;
  }
  // nulls sort after anything else
  else if (!a[orderBy]) {
    return 1;
  } else if (!b[orderBy]) {
    return -1;
  }
  // otherwise, if we're ascending, lowest sorts first
  else if (order === 'asc') {
    return a[orderBy] < b[orderBy] ? -1 : 1;
  }
  // if descending, highest sorts first
  else {
    return a[orderBy] < b[orderBy] ? 1 : -1;
  }
}

function getComparator(order: string, orderBy: string) {
  return (a: { [x: string]: number }, b: { [x: string]: number }) =>
    descendingComparator(a, b, orderBy, order);
}

function stableSort(
  array: any[],
  comparator: {
    (a: { [x: string]: number }, b: { [x: string]: number }): 0 | 1 | -1;
    (arg0: any, arg1: any): any;
  }
) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function ExpandibleRow(props: { row: any; intl: any }) {
  const { row, intl } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const selectedClaim = useSelector(selectSelectedClaim);

  return (
    <React.Fragment>
      <TableRow
        className={classes.root}
        style={
          selectedClaim?.id === row?.id
            ? { background: 'rgba(216, 245, 35, 0.4)' }
            : {}
        }
      >
        <StyledTableCell
          align="left"
          component="th"
          scope="row"
          padding="none"
          style={{ padding: '12px 4px' }}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell
          align="left"
          component="th"
          scope="row"
          padding="none"
          style={{ padding: '12px 4px' }}
        >
          {row?.status === 'closed' && row?.closeReason === 'declined'
            ? ''
            : row.index}
        </StyledTableCell>
        <StyledTableCell
          align="left"
          padding="none"
          style={
            row?.status === 'closed' && row?.closeReason === 'declined'
              ? {
                  padding: '12px 4px',
                  textDecoration: 'line-through',
                  color: '#C9C9C9',
                }
              : { padding: '12px 4px' }
          }
        >
          {row?.damageWhere
            ? intl.formatMessage({
                id: `fleetadmin.damage.where.${row.damageWhere}`,
              })
            : '-'}
        </StyledTableCell>
        <StyledTableCell
          align="left"
          padding="none"
          style={
            row?.status === 'closed' && row?.closeReason === 'declined'
              ? {
                  padding: '12px 4px',
                  textDecoration: 'line-through',
                  color: '#C9C9C9',
                }
              : { padding: '12px 4px' }
          }
        >
          {row?.damageLocation
            ? intl.formatMessage({
                id: `fleetadmin.damage.location.${row.damageLocation}`,
              })
            : '-'}
        </StyledTableCell>
        <StyledTableCell
          align="left"
          padding="none"
          style={
            row?.status === 'closed' && row?.closeReason === 'declined'
              ? {
                  padding: '12px 4px',
                  textDecoration: 'line-through',
                  color: '#C9C9C9',
                }
              : { padding: '12px 4px' }
          }
        >
          {row?.damageSide
            ? intl.formatMessage({
                id: `fleetadmin.damage.side.${row.damageSide}`,
              })
            : '-'}
        </StyledTableCell>
        <StyledTableCell
          align="left"
          padding="none"
          style={
            row?.status === 'closed' && row?.closeReason === 'declined'
              ? {
                  padding: '12px 4px',
                  textDecoration: 'line-through',
                  color: '#C9C9C9',
                }
              : { padding: '12px 4px' }
          }
        >
          {row?.damageComponent
            ? intl.formatMessage({
                id: `fleetadmin.damage.component.${row.damageComponent}`,
              })
            : '-'}
        </StyledTableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                <ClaimInfo claim={row} />
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const RecordTable: React.FC<RecordTableProps> = ({
  intl,
  dataSource,
  // selectedId,
  // onClickRow
}) => {
  const isLoadingCarClaims = useSelector(selectIsLocadingCarClaims);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('index');

  const handleRequestSort = (
    event: any,
    property: React.SetStateAction<string>
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler =
    (property: React.SetStateAction<string>) => (event: any) => {
      handleRequestSort(event, property);
    };

  return (
    <>
      {dataSource?.length > 0 ? (
        <div className="wrapper">
          <TableContainer>
            <Table
              data-testid="RecordTable-table"
              aria-labelledby="tableTitle"
              size={'medium'}
              aria-label="collapsible table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    align="left"
                    padding="none"
                    style={{ padding: '12px 4px', borderTopLeftRadius: 8 }}
                  />
                  {headCells.map((headCell, index) => (
                    <StyledTableCell
                      data-testid={`RecordTable-headCell-${headCell.id}`}
                      key={headCell.id}
                      align="left"
                      padding="none"
                      style={
                        index === 4
                          ? { padding: '12px 4px', borderTopRightRadius: 8 }
                          : { padding: '12px 4px' }
                      }
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        // @ts-ignore
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.label)}
                      >
                        {intl.formatMessage({
                          id: `fleetadmin.damage.${headCell.id}.header`,
                        })}
                      </TableSortLabel>
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(dataSource, getComparator(order, orderBy)).map(
                  (row) => (
                    <ExpandibleRow key={row.name} row={row} intl={intl} />
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : dataSource?.length === 0 && !isLoadingCarClaims ? (
        <Empty style={{ marginTop: '50px' }} description={'No records'} />
      ) : (
        <div className="loaderContainer">
          <Skeleton active paragraph />
          <Skeleton active paragraph />
          <Skeleton active paragraph />
          {isDesktop() && (
            <>
              <Skeleton active paragraph />
              <Skeleton active paragraph />
            </>
          )}
        </div>
      )}
      <style jsx>{styles}</style>
    </>
  );
};

export default RecordTable;
