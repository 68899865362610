import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Radio } from 'antd';
import DrawerModal from '../../components/shared/DrawerModal';
import styles from './DeclineReasonModal.styles';
import { closeModal, getIsVisibleModal } from '../../redux/store/ui/common';
import { selectToken } from '../../redux/store/userData/user';
import { selectSelectedClaim } from '../../redux/store/userData/claims/selectors';
import {
  fetchClaimsByCarId,
  updateClaimIfNeeded,
} from '../../redux/store/userData/claims/thunk';
import { colors } from '../../theme/theme';
import { useIntl } from 'react-intl';

const DeclineReasonModal = ({
  vehicle,
  confirmedNote,
  setEditConfirmStep,
}: any) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const selectedClaim = useSelector(selectSelectedClaim);
  const visibleModal = useSelector(getIsVisibleModal('DeclineReasonModal'));
  const [declineReason, setDeclineReason] = useState(null);

  const handleOnSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setEditConfirmStep(false);
    dispatch(
      updateClaimIfNeeded(
        selectedClaim?.id!,
        {
          status: 'closed',
          closeReason: 'declined',
          closedNote: confirmedNote,
          declineReason,
        },
        () =>
          setTimeout(() => {
            dispatch(fetchClaimsByCarId(vehicle?.id));
          }, 1000)
      )
    );
    dispatch(closeModal('DeclineReasonModal'));
  };

  const handleOnCancel = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(closeModal('DeclineReasonModal'));
  };

  const handleOnChange = (e: any) => {
    setDeclineReason(e.target.value);
  };

  useEffect(() => setDeclineReason(null), [visibleModal]);

  return (
    <>
      <DrawerModal
        visible={visibleModal}
        onClose={() => {
          dispatch(closeModal('DeclineReasonModal'));
        }}
      >
        <div className="decline-reason-modal">
          <div className="connection-selector-wrapper">
            <p className="label">Decline reason</p>
            <Radio.Group
              onChange={handleOnChange}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <Radio value="Abnutzung (worn)">Abnutzung (worn)</Radio>
              <Radio value="Verjährt (lapsed)">Verjährt (lapsed)</Radio>
              <Radio value="Mehrfach (duplicate)">Mehrfach (duplicate)</Radio>
              <Radio value="Sonstiges (other)">Sonstiges (other)</Radio>
            </Radio.Group>
          </div>
          <Button
            className="submit-button"
            onClick={handleOnSubmit}
            disabled={!declineReason}
            style={!declineReason ? { background: colors.buttonDisabled } : {}}
          >
            {intl.formatMessage({
              id: `submit`,
            })}
          </Button>
          <Button className="cancel-button" onClick={handleOnCancel}>
            {intl.formatMessage({
              id: `cancel`,
            })}
          </Button>
        </div>
      </DrawerModal>
      <style jsx>{styles}</style>
    </>
  );
};

export default DeclineReasonModal;
