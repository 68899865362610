import React from 'react';
import { useIntl } from 'react-intl';
import { Select } from 'antd';
import styles from './SelectorLayout.styles';
import { zIndex } from '../../theme/theme';

const { Option } = Select;

export const arrayToOptions = (arr: Array<string>) =>
  arr.map((item) => ({
    value: item,
    label: item,
  }));

type SelectorLayoutProps = {
  style?: any;
  className?: string;
  mode?: 'multiple' | 'tags';
  value?: any;
  placeholder?: string;
  loading?: boolean;
  onSelect?: (value: string) => void;
  options?: any[];
  keyPrefix: string;
  disabled?: boolean;
  testid?: string;
};

const SelectorLayout: React.FC<SelectorLayoutProps> = ({
  style,
  className,
  mode,
  disabled = false,
  value,
  placeholder,
  loading,
  onSelect,
  options,
  keyPrefix,
  testid,
}) => {
  const intl = useIntl();

  const _placeholder =
    placeholder ||
    intl.formatMessage({
      id: 'fleetadmin.searchBox.fallback.placeholder',
    });

  return (
    <div style={style} className={className}>
      <Select
        data-testid={`SelectorLayout-${testid}`}
        dropdownStyle={{ zIndex: zIndex.dropDown }}
        mode={mode}
        allowClear={true}
        value={value || undefined}
        style={{ width: '100%' }}
        placeholder={_placeholder}
        optionFilterProp="children"
        loading={loading}
        onChange={onSelect}
        disabled={disabled}
      >
        {options?.map((option) => (
          <Option
            data-testid={`SelectorLayout-${testid}-${option.value}`}
            key={`${keyPrefix}-option-${option.value}`}
            value={option.value || undefined}
          >
            {option.label}
          </Option>
        ))}
      </Select>
      <style jsx>{styles}</style>
    </div>
  );
};

export default SelectorLayout;
