import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal, Upload } from 'antd';
import styles from './UploadFileButton.styles';
import {
  uploadAreaFileIfNeeded,
  selectSelectedArea,
} from '../../redux/store/appData/areas';
import { selectToken } from '../../redux/store/userData/user';

const UploadFileButton: React.FC = () => {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const selectedArea = useSelector(selectSelectedArea);

  const handleBeforeUpload = (file: any) => {
    Modal.info({
      title: 'Upload file (*.kml)',
      content: file.name,
      okText: 'Upload file',
      onOk() {
        dispatch(uploadAreaFileIfNeeded(token, selectedArea?.id!, file));
      },
    });
    return false;
  };

  return (
    <>
      <Upload
        className="area-file-upload"
        name="file"
        accept=".kml, .xml"
        showUploadList={false}
        beforeUpload={handleBeforeUpload}
      >
        <Button
          data-testid="UploadFileButton-uploadBtn"
          className="upload-file"
          type="link"
        >
          Upload File
        </Button>
      </Upload>
      <style jsx>{styles}</style>
    </>
  );
};

export default UploadFileButton;
