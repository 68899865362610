import React, { useState } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useSelector } from 'react-redux';
import ClaimTabLayout from '../../components/shared/ClaimTabLayout';
import VINAndPlate from '../../components/shared/VINAndPlate';
import ClaimInfo from './ClaimInfo';
import styles from './ClaimDrawerBody.styles';
import { selectCars, carDamages } from '../../redux/store/userData/cars';
import {
  selectSelectedClaim,
  selectClaims,
} from '../../redux/store/userData/claims';
import ClaimStatusLabel from '../shared/ClaimStatusLabel';
import ClaimStatus from './ClaimStatus';
import { colors } from '../../theme/theme';
import { useIntl } from 'react-intl';

const ClaimDrawerBody: React.FC = () => {
  const intl = useIntl();
  const selectedClaim = useSelector(selectSelectedClaim);
  const [activeTab, setActiveTab] = useState('claim-drawer-tab-0');
  const cars = useSelector(selectCars);
  const claims = useSelector(selectClaims);
  const selectedDamages = useSelector(carDamages);
  const VIN = cars?.find(
    (car) => car.id === selectedClaim?.carId
  )?.chassisNumber;
  const claimRecords =
    selectedDamages?.length > 0
      ? selectedDamages?.filter(
          (claim: any) => claim.carId === selectedClaim?.carId
        )
      : claims?.filter((claim) => claim.carId === selectedClaim?.carId);
  const claimIndex =
    claimRecords?.length! -
    claimRecords?.findIndex((record) => record.id === selectedClaim?.id)!;

  const tabs = [
    {
      title: intl.formatMessage({
        id: 'fleetadmin.claim.tab.status',
      }),
      content: (
        <>
          <ClaimStatus
            claimStatus={selectedClaim?.status || 'open'}
            closeReason={selectedClaim?.closeReason || ''}
          />
        </>
      ),
    },
    {
      title: intl.formatMessage({
        id: 'fleetadmin.claim.tab.info',
      }),
      content: (
        <>
          <VINAndPlate
            chassisNumber={VIN}
            plate={selectedClaim?.plate}
            carId={selectedClaim?.carId}
          />
          <div style={{ marginLeft: '16px', marginRight: '30px' }}>
            <ClaimInfo claim={selectedClaim!} index={claimIndex} />
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="claim-drawer-body">
        <div
          data-testid={`ClaimDrawerBody-claimDrawerTop-${selectedClaim?.damageId}`}
          className="claim-drawer-top"
        >
          <p className="claim-code">{selectedClaim?.damageId}</p>
        </div>
        <div className="claim-status-wrapper">
          <ClaimStatusLabel status={selectedClaim?.status || 'open'} />
          {selectedClaim?.status === 'closed' && (
            <div className="close-status-wrapper">
              <img
                src={
                  selectedClaim?.closeReason === 'invoiced'
                    ? '/images/timeline/tickGreen.svg'
                    : '/images/timeline/closeGrey.svg'
                }
                style={{ width: 16, height: 16, marginRight: 4 }}
              />
              <div
                style={
                  selectedClaim?.closeReason === 'invoiced'
                    ? { color: colors.claim.closed }
                    : {}
                }
              >
                {intl.formatMessage({
                  id: `fleetadmin.claim.reason.${selectedClaim?.closeReason}`,
                })}
              </div>
            </div>
          )}
        </div>
        <ClaimTabLayout
          tabs={tabs}
          keyName="claim-drawer"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <style jsx>{styles}</style>
      </div>
    </>
  );
};

export default ClaimDrawerBody;
