import { RootState } from '../../../config';
import { StatusModal } from './initialState';

export const selectAppliedFiltersHeight = (state: RootState) =>
  state.ui.common.appliedFiltersHeight;

export const selectDisplayColumnsForCar = (state: RootState) =>
  state.ui.common.displayColumns.car;

export const selectDisplayColumnsForTimeTable = (state: RootState) =>
  state.ui.common.displayColumns.timeTable;

export const selectDisplayColumnsForArea = (state: RootState) =>
  state.ui.common.displayColumns.area;

export const selectDisplayColumnsForCategory = (state: RootState) =>
  state.ui.common.displayColumns.category;

export const selectDisplayColumnsForPricing = (state: RootState) =>
  state.ui.common.displayColumns.pricing;

export const selectDisplayColumnsForPrivacyPolicy = (state: RootState) =>
  state.ui.common.displayColumns.privacyPolicy;

export const selectDisplayColumnsForReceipts = (state: RootState) =>
  state.ui.common.displayColumns.receipts;

export const selectDisplayColumnsForProfile = (state: RootState) =>
  state.ui.common.displayColumns.profile;

export const selectDisplayColumnsForCare = (state: RootState) =>
  state.ui.common.displayColumns.care;

export const selectDisplayColumnsForTrip = (state: RootState) =>
  state.ui.common.displayColumns.trip;

export const selectDisplayColumnsForBooking = (state: RootState) =>
  state.ui.common.displayColumns.booking;

export const selectDisplayColumnsForClaim = (state: RootState) =>
  state.ui.common.displayColumns.claim;

export const selectDisplayColumnsForRecord = (state: RootState) =>
  state.ui.common.displayColumns.record;

export const selectDisplayColumnsForCircle = (state: RootState) =>
  state.ui.common.displayColumns.circle;

export const selectDisplayColumnsForUsers = (state: RootState) =>
  state.ui.common.displayColumns.users;

export const selectDisplayColumnsForAdmins = (state: RootState) =>
  state.ui.common.displayColumns.admins;

export const selectConfirmModal = (state: RootState): any =>
  state.ui.common.confirmModal;

export const selectStatusModal = (state: RootState): StatusModal =>
  state.ui.common.statusModal;

export const getIsVisibleModal =
  (modal: any) =>
  (state: RootState): boolean =>
    // @ts-ignore
    state.ui.common.visibleModals.includes(modal);

export const selectViewMode = (state: RootState) => state.ui.common.viewMode;

export const selectViewType = (state: RootState) => state.ui.common.viewType;

export const selectSelectedPayment = (state: RootState) =>
  state.ui.common.selectedPayment;

export const selectSelectedDrawersCount = (state: RootState): number => {
  const selectedCarCount = state.userData.cars.selected ? 1 : 0;
  const selectedAreaCount = state.appData.areas.selected ? 1 : 0;
  const selectedClaimCount = state.userData.claims.selected ? 1 : 0;
  const selectedUsersCount = state.userData.users?.selected ? 1 : 0;
  const selectedTripsCount = state.userData.trips.selected ? 1 : 0;
  const selectedBookingsCount = state.userData.trips.selectedBooking ? 1 : 0;
  const selectedCaresCount = state.userData.cares.selected ? 1 : 0;
  const selectedReceiptsCount = state.userData.receipts.selected ? 1 : 0;
  const selectedAdminsCount = state.userData.admins.selected ? 1 : 0;
  const selectedProfilesCount = state.userData.profiles.selected ? 1 : 0;
  const selectedCirclesCount = state.userData.circles.selected ? 1 : 0;
  const selectedCategoriesCount = state.userData.categories.selected ? 1 : 0;
  const selectedPrivacyPolicyCount = state.appData.privacyPolicies.selected
    ? 1
    : 0;

  return (
    selectedCarCount +
    selectedPrivacyPolicyCount +
    selectedAreaCount +
    selectedCategoriesCount +
    selectedClaimCount +
    selectedBookingsCount +
    selectedCaresCount +
    selectedAdminsCount +
    selectedCirclesCount +
    selectedProfilesCount +
    selectedReceiptsCount +
    selectedUsersCount +
    selectedTripsCount
  );
};
