import React from 'react';
import ListEditItem from '../../../shared/ListEditItem';
import { renderText } from '../../../shared/DrawerLayout/renderText';
import styles from '../UserInfo.styles';

interface UserContactInfoProps {
  selectedUser: any;
  intl: any;
  onEditContact: () => void;
}

const UserContactInfo: React.FC<UserContactInfoProps> = ({
  selectedUser,
  intl,
  onEditContact,
}) => {
  const address = selectedUser?.address?.streetName
    ? `${selectedUser.address.streetName}, ${
        selectedUser.address.streetNumber
      }, ${selectedUser.address.postalCode}, ${selectedUser.address.city}, ${
        selectedUser.address.country || 'DE'
      }`
    : null;

  return (
    <>
      <div className="user-section-title">
        {intl.formatMessage({ id: `fleetadmin.users.info.contact` })}
      </div>
      <ListEditItem
        label={intl.formatMessage({ id: `fleetadmin.users.info.phone` })}
        leftItem={renderText(selectedUser.phoneNumber, false)}
        verified={selectedUser?.verified?.phoneNumber}
        onClickEdit={onEditContact}
      />
      <ListEditItem
        label={intl.formatMessage({ id: `fleetadmin.users.info.email` })}
        leftItem={renderText(selectedUser.email, false)}
        verified={selectedUser?.emailVerified}
        onClickEdit={onEditContact}
      />
      <ListEditItem
        label={intl.formatMessage({ id: `fleetadmin.users.info.address` })}
        leftItem={renderText(address, false)}
        verified={selectedUser?.addressVerified}
        onClickEdit={onEditContact}
      />
      {selectedUser?.locale && (
        <ListEditItem
          label={intl.formatMessage({ id: `fleetadmin.users.info.locale` })}
          leftItem={renderText(selectedUser.locale, false)}
        />
      )}
      <style jsx>{styles}</style>
    </>
  );
};

export default UserContactInfo;
