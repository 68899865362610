import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import getColumns from './getColumns';
import TableLayout from '../../components/shared/TableLayout';
import {
  selectDisplayCars,
  selectSelectedCar,
  selectLastCarsStatus,
} from '../../redux/store/userData/cars';
import {
  selectDisplayColumnsForCar,
  selectCarWithoutMapEffect,
} from '../../redux/store/ui/common';
import { selectFleets } from '../../redux/store/appData/fleets';
import { selectAreas } from '../../redux/store/appData/areas';
import { FleetAdminCarData } from '../../@types';
import { getFilteredColumns } from '../../utils';

const CarTable = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const displayCars = useSelector(selectDisplayCars);
  const fleets = useSelector(selectFleets);
  const areas = useSelector(selectAreas);
  const selectedCar = useSelector(selectSelectedCar);
  const displayColumns = useSelector(selectDisplayColumnsForCar);
  const lastCarsStatus = useSelector(selectLastCarsStatus);
  const selectedId = selectedCar ? selectedCar.id : null;
  const columns = getColumns({ fleets, areas, lastCarsStatus, intl, dispatch });
  const filteredColumns = getFilteredColumns(columns, displayColumns);

  const onClickRow = (record: FleetAdminCarData) => {
    dispatch(selectCarWithoutMapEffect(record));
  };

  return (
    <TableLayout
      rowKey="car"
      columns={filteredColumns}
      dataSource={displayCars}
      selectedId={selectedId}
      totalNumber={displayCars?.length}
      onClickRow={onClickRow}
    />
  );
};

export default CarTable;
