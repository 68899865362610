import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  noVehicleStyles,
  assignedVehiclesStyles,
} from './AssignedVehicles.styles';
import { selectCars } from '../../redux/store/userData/cars';
import { selectCarAndSwitchToMapView } from '../../redux/store/ui/common';
import { selectSelectedArea } from '../../redux/store/appData/areas';

const NoVehicle = () => {
  return (
    <>
      <div>
        <p className="message">There are no vehicles assigned in this area</p>
      </div>
      <style jsx>{noVehicleStyles}</style>
    </>
  );
};

const AssignedVehicles = () => {
  const dispatch = useDispatch();
  const cars = useSelector(selectCars);
  const selectedArea = useSelector(selectSelectedArea);
  const assignedVehicles = cars?.filter(
    (car) => car.areaId === selectedArea?.id
  );

  return (
    <>
      <ul data-testid="AssignedVehicles-content" className="assigned-vehicles">
        {assignedVehicles?.length === 0 && <NoVehicle />}
        {assignedVehicles?.map((vehicle, id) => {
          const handleOnClick = (e: any) => {
            e.preventDefault();
            dispatch(selectCarAndSwitchToMapView(vehicle));
          };
          return (
            <li
              className="assigned-vehicle"
              key={`assigned-vehicle-${id}`}
              onClick={handleOnClick}
            >
              {vehicle.chassisNumber}
            </li>
          );
        })}
      </ul>
      <style jsx>{assignedVehiclesStyles}</style>
    </>
  );
};

export default AssignedVehicles;
